import { GET_DOCUMENT } from './-getter-types';
import { useDocumentsStore } from '~/stores/documents';
import { type Document } from '~/models/Document';

const Getters = {
    [GET_DOCUMENT]: (): Document | null => {
        const documentsStore = useDocumentsStore();

        return documentsStore.document;
    },
};

export default Getters;
