import { SET_DOCUMENT } from './-mutation-types';
import { useDocumentsStore } from '~/stores/documents';
import DocumentService from '~/services/api/crm/DocumentService';
import type { Document } from '~/models/Document';

const Actions = {
    async fetchDocument(documentId: number) {
        const documentsStore = useDocumentsStore();
        const documentService = new DocumentService();
        const response = await documentService.fetchDocument(documentId);

        documentsStore[SET_DOCUMENT](response);
    },
    setDocument(document: Document | null) {
        const documentsStore = useDocumentsStore();
        documentsStore[SET_DOCUMENT](document);
    },
};

export default Actions;
